<template>
  <div class="background">
    <Inbox />
  </div>
</template>

<script>
import Inbox from "@/components/elements/dashboard/inbox/Inbox";

export default {
  name: "DocumentManagementInbox",

  components: {
    Inbox,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
