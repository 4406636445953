<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <div class="d-flex flex-column flex-md-row">
        <!-- Start Day -->
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mx-2"
              v-model="startDate"
              label="Start Day"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="startDate"
            @input="startDateMenu = false"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>

        <!-- End Day -->
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mx-2"
              v-model="endDate"
              label="End day"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="endDate"
            @input="endDateMenu = false"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>

        <!-- Date By -->
        <v-select
          class="mx-2"
          v-model="dateBy"
          :items="lists.dateBy"
          label="Date By"
          outlined
          dense
        ></v-select>

        <!-- Team Member-->
        <v-select
          class="mx-2"
          v-model="teamMember"
          :items="usersEmails"
          label="Team Member"
          outlined
          dense
        ></v-select>

        <v-btn @click="refreshData()" color="primary"> Get Data </v-btn>
      </div>

      <!-- Search -->
      <div class="d-flex justify-end">
        <v-card flat>
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
          ></v-text-field> -->
        </v-card>
      </div>

      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="documents"
          :search="search"
          sort-by="id"
          :sort-desc="true"
          multi-sort
          class="elevation-1"
          :page.sync="page"
          :items-per-page="documentsPerPage"
          hide-default-footer
        >
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:body.prepend>
            <tr>
              <!--  -->
              <!-- <td></td> -->
              <!--  -->
              <td></td>
              <!--  -->
              <td></td>
              <!-- Id Search -->
              <td>
                <v-text-field
                  v-model="idSearch"
                  type="text"
                  label="Id Search"
                ></v-text-field>
              </td>
              <!--  -->
              <td></td>
              <!--  -->
              <td></td>
              <!--  -->
              <td></td>
              <!--  -->
              <td></td>
              <!--  -->
              <td></td>
              <!--  -->
              <td></td>
              <!--  -->
              <td></td>
              <!--  -->
              <td></td>
              <!--  -->
              <td></td>
              <!--  -->
              <td></td>
              <!--  -->
              <td></td>
            </tr>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Documents</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <!-- <template
                  v-if="$can('document_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Document
                  </v-btn>
                </template> -->

                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- Vendor Name -->
                          <!-- <v-col cols="12">
                            <v-text-field
                              v-model="editedDocument.vendor_name"
                              :rules="vendorNameRules"
                              label="Vendor Name"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Vendor Name <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Date -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-menu
                              v-model="dateMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="date"
                                  label="Date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <template v-slot:label>
                                    <p class="body-1">
                                      Date <span class="red--text">*</span>
                                    </p>
                                  </template>
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="date"
                                @input="dateMenu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col> -->

                          <!-- Tax -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocument.tax"
                              :rules="taxRules"
                              label="Tax"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Tax
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Total Before Tax -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocument.total_before_tax"
                              :rules="totalBeforeTaxRules"
                              label="Total Before Tax"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Total Before Tax
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Total -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocument.total"
                              :rules="totalRules"
                              label="Total"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Total
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Total Currency -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocument.total_currency"
                              :rules="totalCurrencyRules"
                              label="Total Currency"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Total Currency
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Companies -->
                          <v-col
                            v-if="$can('super_admin')"
                            class="mt-n5"
                            cols="12"
                          >
                            <v-select
                              v-model="companyName"
                              :items="companyNamesList"
                              :rules="companyNameRules"
                              attach
                              label="Companies"
                            >
                              <template v-slot:label>
                                <p class="body-1">Company</p>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- dialogImage -->
              <v-dialog v-model="dialogImage" persistent max-width="500px">
                <template
                  v-if="$can('image_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Document
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="formImage" lazy-validation>
                        <v-row>
                          <!-- Files -->
                          <v-col class="mt-n5" cols="12">
                            <v-file-input
                              v-model="files"
                              :rules="filesRules"
                              multiple
                              counter
                              show-size
                              placeholder="Click here to select files"
                            ></v-file-input>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeImage">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="saveImage">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this
                    document?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="btn btn-primary"
                      @click="deleteDocumentConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- checkbox -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.checkbox="{ item }">
            <v-simple-checkbox v-model="item.checkbox"></v-simple-checkbox>
          </template>

          <!-- View -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.view="{ item }">
            <v-btn
              color="primary"
              :to="{
                name: 'DashboardInboxDetails',
                params: { id: item.id },
              }"
            >
              View {{ item.view }}
              <v-icon size="36" right> mdi-dots-vertical </v-icon></v-btn
            >
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">
                  {{ item.actions }}
                  <v-icon size="36" color="primary"> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <!-- img -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.img="{ item }">
            <div v-if="item.images.length > 0">
              <div v-if="item.images[0].name.split('.').pop() == 'pdf'">
                <v-img
                  alt="img"
                  class="img my-1"
                  src="@/assets/img/pdf/pdf-thumbnail.png"
                  lazy-src="https://via.placeholder.com/150"
                  max-width="50"
                  max-height="60"
                  contain
                />
              </div>

              <div v-else>
                <div v-if="item.images[0].thumbnail_url != null">
                  <v-img
                    alt="img"
                    class="img my-1"
                    :src="item.images[0].thumbnail_url"
                    lazy-src="https://via.placeholder.com/150"
                    max-width="50"
                    max-height="60"
                    contain
                  />
                </div>

                <div v-else>
                  <v-img
                    alt="img"
                    class="img my-1"
                    :src="item.images[0].url"
                    lazy-src="https://via.placeholder.com/150"
                    max-width="50"
                    max-height="60"
                    contain
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <v-img
                alt="img"
                class="img my-1"
                lazy-src="https://via.placeholder.com/150"
                src="https://via.placeholder.com/150"
                max-width="50"
                max-height="60"
                contain
              />
            </div>
          </template>

          <!-- date -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.date="{ item }">
            {{ $helpers.date(item.date) }}
          </template>

          <!-- time -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.time="{ item }">
            {{ $helpers.time(item.time) }}
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <!-- <v-icon
              v-if="$can('document_update')"
              small
              class="mr-2"
              @click="editDocument(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon> -->
            <v-icon
              v-if="$can('document_delete')"
              small
              @click="deleteDocument(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Documents per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementDocuments",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      dateMenu: false,

      dialog: false,
      dialogImage: false,
      dialogDelete: false,

      documents: [],
      companies: [],
      companyName: "",
      companyNamesList: [],
      editedIndex: -1,
      editedDocument: {
        id: 0,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
        time: "",
        vendor_name: "",
        tax: 0,
        total_before_tax: 0,
        total: 0,
        total_currency: 0,
        company_id: 0,
        created_at: "",
        updated_at: "",
        images: [],
        documentlines: [],
      },
      defaultDocument: {
        id: 0,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
        time: "",
        vendor_name: "",
        tax: 0,
        total_before_tax: 0,
        total: 0,
        total_currency: 0,
        company_id: 0,
        created_at: "",
        updated_at: "",
        images: [],
        documentlines: [],
      },

      // files
      files: null,
      filesRules: [(v) => !!v || "Required"],

      //

      startDate: null,
      startDateMenu: false,

      endDate: null,
      endDateMenu: false,

      dateBy: "Created At",
      teamMember: "All",

      lists: {
        dateBy: ["Created At", "Updated At"],
      },

      search: "",
      idSearch: "",
      //

      // vendor name
      vendorNameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // tax
      taxRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9\s]+$/.test(v) || "Tax must contain only 0 to 9",
        (v) => (v && v >= 0) || "Tax should be 0 or above",
      ],

      // total before tax
      totalBeforeTaxRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[0-9\s]+$/.test(v) || "Total Before Tax must contain only 0 to 9",
        (v) => (v && v >= 0) || "Total Before Tax should be 0 or above",
      ],

      // total
      totalRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9\s]+$/.test(v) || "Total must contain only 0 to 9",
        (v) => (v && v >= 0) || "Total should be 0 or above",
      ],

      // total currency
      totalCurrencyRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[0-9\s]+$/.test(v) || "Total currency must contain only 0 to 9",
        (v) => (v && v >= 0) || "Total currency should be 0 or above",
      ],

      // companyName
      companyNameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      page: 1,
      documentsPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],

      users: [],
      usersEmails: [],

      url: "",

      useFilters: false,
      useStartDateFilter: false,
      useEndDateFilter: false,
      useTeamMemberFilter: false,
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    formTitle() {
      return this.editedIndex === -1 ? "New Document" : "Edit Document";
    },

    headers() {
      return [
        // { text: "", sortable: false, value: "checkbox" },
        { text: "", sortable: false, value: "view" },
        { text: "", sortable: false, value: "img" },
        {
          text: "Document Id",
          align: "start",
          value: "id",
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["idSearch"].toLowerCase());
          },
        },
        // { text: "Document Date", value: "date" },
        // { text: "Document Time", value: "time" },
        // { text: "Vendor Name", value: "vendor_name" },
        // { text: "Tax", value: "tax" },
        // { text: "Total Before Tax", value: "total_before_tax" },
        // { text: "Total", value: "total" },
        // { text: "Total Currency", value: "total_currency" },
        { text: "Company Id", value: "company_id" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Action", value: "actions", sortable: false },
      ];
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedFilterDocuments();
    },

    // documentsPerPage
    documentsPerPage() {
      this.getApiPagedFilterDocuments();
    },

    // perPageChoice
    perPageChoice(val) {
      this.documentsPerPage = val.value;
      this.page = 1;
    },

    // startDate
    startDate() {
      this.useStartDateFilter = true;
      this.useFilters = false;
    },

    // endDate
    endDate() {
      this.useEndDateFilter = true;
      this.useFilters = false;
    },

    // teamMember
    teamMember() {
      this.useTeamMemberFilter = true;
      this.useFilters = false;
    },
  },

  created() {
    if (!this.$can("document_access")) {
      this.$router.push({ name: "DashboardInbox" });
    }

    this.url = `documents/paged-filter?page_no=${this.page}&page_size=${this.documentsPerPage}`;

    // this.getApiDocuments();
    this.getApiUsers();
    this.getApiPagedFilterDocuments();

    if (this.$can("super_admin")) {
      this.getApiCompanies();
    }
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiDocument();
        } else {
          this.createApiDocument();
        }
      }
    },

    // validateFormImage
    validateFormImage() {
      this.$refs.formImage.validate();

      if (this.$refs.formImage.validate()) {
        this.createApiImage();
      }
    },

    // editDocument
    editDocument(document) {
      this.editedIndex = this.documents.indexOf(document);
      this.editedDocument = Object.assign({}, document);
      this.dialog = true;

      if (this.editedIndex != -1) {
        this.companyName = this.$helpers.getNameByIdFromList(
          this.companies,
          document.company_id
        );
      }

      if (this.editedIndex !== -1) {
        this.$refs.form.validate();
      }

      // this.$refs.form.resetValidation();
    },

    // deleteDocument
    deleteDocument(document) {
      this.editedIndex = this.documents.indexOf(document);
      this.editedDocument = Object.assign({}, document);
      this.dialogDelete = true;
    },

    // deleteDocumentConfirm
    deleteDocumentConfirm() {
      this.deleteApiDocument();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedDocument = Object.assign({}, this.defaultDocument);
        this.editedIndex = -1;
        this.companyName = "";
      });

      this.$refs.form.resetValidation();
    },

    // closeImage
    closeImage() {
      this.dialogImage = false;
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedDocument = Object.assign({}, this.defaultDocument);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // saveImage
    saveImage() {
      this.validateFormImage();
    },

    // Get Api Documents
    async getApiDocuments() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("documents");

      if (res.status == 200) {
        this.documents = res.data;
      }

      this.overlay = false;
    },

    // getApiPagedDocuments
    async getApiPagedDocuments() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `documents/paged-filter?page_no=${this.page}&page_size=${this.documentsPerPage}`
      );

      if (res.status == 200) {
        this.documents = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // getApiPagedFilterDocuments
    async getApiPagedFilterDocuments() {
      this.overlay = true;

      this.setUrl();

      const res = await this.$helpers.getApiData(this.url);

      if (res.status == 200) {
        this.documents = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // Create Api Document
    async createApiDocument() {
      this.overlay = true;

      var reqData = {
        // vendor_name: this.editedDocument.vendor_name,
        tax: parseInt(this.editedDocument.tax),
        total_before_tax: parseInt(this.editedDocument.total_before_tax),
        total: parseInt(this.editedDocument.total),
        total_currency: parseInt(this.editedDocument.total_currency),
        company_id: this.auth.user.company_id,
      };

      if (this.companyName != "") {
        var id = this.$helpers.getIdByNameFromList(
          this.companies,
          this.companyName
        );

        if (id > 0) {
          reqData.company_id = id;
        }
      }

      const res = await this.$helpers.createApiData(
        "documents",
        reqData,
        "Document Created Successfully!"
      );

      if (res.status == 200) {
        this.documents.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Update Api Document
    async updateApiDocument() {
      this.overlay = true;

      var reqData = {
        id: this.editedDocument.id,
        vendor_name: this.editedDocument.vendor_name,
        tax: parseInt(this.editedDocument.tax),
        total_before_tax: parseInt(this.editedDocument.total_before_tax),
        total: parseInt(this.editedDocument.total),
        total_currency: parseInt(this.editedDocument.total_currency),
        company_id: this.auth.user.company_id,
      };

      if (this.companyName != "") {
        var id = this.$helpers.getIdByNameFromList(
          this.companies,
          this.companyName
        );

        if (id > 0) {
          reqData.company_id = id;
        }
      }

      const res = await this.$helpers.updateApiData(
        `documents/${this.editedDocument.id}`,
        reqData,
        "Document Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.documents[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Delete Api Document
    async deleteApiDocument() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `documents/${this.editedDocument.id}`,
        "Document Deleted Successfully!"
      );

      if (res.status == 200) {
        this.documents.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },

    // Get Api Companies
    async getApiCompanies() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("companies");

      if (res.status == 200) {
        this.companies = res.data;

        this.companyNamesList = this.$helpers.getNamesFromList(this.companies);
      }

      this.overlay = false;
    },

    // Create Api Image
    async createApiImage() {
      try {
        this.overlay = true;

        // let formData = new FormData();
        // formData.append("file", this.files);

        let formData = new FormData();

        for (var i = 0; i < this.files.length; i++) {
          let file = this.files[i];

          // console.log(i);

          formData.append("files[" + i + "]", file);
        }

        let res = await this.axios.post(
          "images/document-with-image",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // console.log(res);

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Document Created Successfully!",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          let data = res.data;

          // console.log(data);

          this.documents.push(data);

          this.files = null;

          this.closeImage();
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        if (error.response.status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          // console.log(res);

          // const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // Get Api Users
    async getApiUsers() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("users");

      if (res.status == 200) {
        this.users = res.data;

        this.usersEmails = ["All"];

        for (var i = 0; i < this.users.length; i++) {
          this.usersEmails.push(this.users[i].contact.email);
        }
      }

      this.overlay = false;
    },

    // refreshData
    refreshData() {
      this.useFilters = true;

      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getApiPagedFilterDocuments();
      }
    },

    // setUrl
    setUrl() {
      this.url = `documents/paged-filter?page_no=${this.page}&page_size=${this.documentsPerPage}`;

      // date_by
      this.url += `&date_by=${this.dateBy.toLowerCase().replace(" ", "_")}`;

      // useStartDateFilter
      if (this.useStartDateFilter && this.useFilters) {
        // startDate
        if (this.startDate != null) {
          this.url += `&start_day=${this.startDate}`;
        }
      }

      // useEndDateFilter
      if (this.useEndDateFilter && this.useFilters) {
        // endDate
        if (this.endDate != null) {
          this.url += `&end_day=${this.endDate}`;
        }
      }

      // useTeamMemberFilter
      if (this.useTeamMemberFilter && this.useFilters) {
        // teamMember
        if (this.teamMember != null && this.teamMember != "All") {
          var teamMemberId = 0;

          for (var i = 0; i < this.users.length; i++) {
            if (this.users[i].contact.email == this.teamMember) {
              teamMemberId = this.users[i].id;

              break;
            }
          }

          if (teamMemberId > 0) {
            this.url += `&team_member_id=${teamMemberId}`;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
